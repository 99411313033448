var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "operproccess__coming"
    }
  }, [_c('div', {
    staticClass: "b-table__wrapper"
  }, [_c('manufacture-documents-header', {
    attrs: {
      "page_type": "equipment"
    },
    on: {
      "date_range": _vm.updateDateRange,
      "create_document": _vm.create
    }
  }), _c('b-card', {
    staticClass: "p-0 b-table__content",
    attrs: {
      "no-body": ""
    }
  }, [_c('documents-table-filter', {
    ref: "doc_table_filter",
    attrs: {
      "placeholder": "№ Документа",
      "table_name": "equipments-docs"
    },
    on: {
      "handler_pagination": _vm.handlerSearch,
      "clear": _vm.clearSearchStr
    }
  }), _c('b-table', {
    staticClass: "table-docs",
    attrs: {
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fields,
      "tbody-tr-class": "cursor"
    },
    on: {
      "row-clicked": _vm.openDocument
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center mt-2"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', {
          staticClass: "center bold mt-2"
        }, [_vm._v("Загрузка...")])], 1)];
      },
      proxy: true
    }, {
      key: "head(id)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.select_all,
            callback: function ($$v) {
              _vm.select_all = $$v;
            },
            expression: "select_all"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: data.item.fixed
          }
        }), _c('b-form-checkbox', {
          staticClass: "cursor",
          attrs: {
            "value": data.value
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        })];
      }
    }, {
      key: "cell(date)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "b-date"
        }, [_vm._v(_vm._s(data.item.number))]), _c('div', {
          staticClass: "b-date date mt-1"
        }, [_vm._v(" " + _vm._s(data.item.date ? _vm.convertDate(data.item.date) : '') + " ")])];
      }
    }, {
      key: "cell(fixed)",
      fn: function (data) {
        return [_c('b-badge', {
          class: {
            success: data.item.fixed
          }
        }, [_vm._v(" " + _vm._s(data.item.fixed ? 'Проведен' : 'Черновик') + " ")])];
      }
    }, {
      key: "cell(sum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.value) || 0) + " ₽ ")])];
      }
    }, {
      key: "cell(retail_sum)",
      fn: function (data) {
        return [_c('span', {
          staticClass: "d-flex float-right"
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.value) || 0) + " ₽ ")])];
      }
    }, {
      key: "cell(owner)",
      fn: function (data) {
        var _data$item$owner, _data$item$owner2;

        return [_c('span', {
          staticClass: "d-flex float-left"
        }, [data.item.owner ? [_vm._v(" " + _vm._s((_data$item$owner = data.item.owner) === null || _data$item$owner === void 0 ? void 0 : _data$item$owner.first_name) + " " + _vm._s((_data$item$owner2 = data.item.owner) === null || _data$item$owner2 === void 0 ? void 0 : _data$item$owner2.last_name) + " ")] : [_vm._v("–")]], 2)];
      }
    }])
  })], 1), _c('table-navbar', {
    attrs: {
      "clear_selected": _vm.clearSelected,
      "items": _vm.selected,
      "items_name": "documents",
      "doc_type": "manufacture",
      "print": false
    },
    on: {
      "remove_items": _vm.remove_items
    }
  }), _c('pagination', {
    attrs: {
      "total": _vm.items_total,
      "table_name": "equipments-docs"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }